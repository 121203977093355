// @ts-ignore
import debounce from 'lodash/debounce';
// @ts-ignore
import uniqBy from 'lodash/uniqBy';
import {Backdrop, Box, CircularProgress, Pagination} from "@/node_modules/@mui/material/index";
import React, {useState, useRef, useEffect, useCallback} from "react";
import {callApiGetGarments, callApiGetColors} from "../common/api";
import OutlinedButton from "@/app/components/OutlinedButton";
import LargeCheckbox from "@/app/components/LargeCheckbox";
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Tune from '@mui/icons-material/Tune';
import Male from '@mui/icons-material/Male';
import Female from '@mui/icons-material/Female';
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import Cameraswitch from "@mui/icons-material/Cameraswitch";
import {useTheme, Zoom, Fade, Slide} from "@mui/material";
import {useAuth} from "@/app/context/AuthContext";


const couponName = 'GLG-FREE';
export const ProductInfo = ({
                              hidePrimaryAction = false,
                              productInfoDetails,
                              isAddedToWishlist,
                              handleToggleInWishlist,
                              tryOnTaskResults,
                              goToGallery,
                              handleTryOn
                            }: any) => {
  const [previewImageIndex, setPreviewImageIndex] = useState(0);
  const productInfo = productInfoDetails?.garments?.[0] ?? {};
  const {hasCoupon} = useAuth();

  const {name, item_type, color, color_hex = [], description, product_url_qr} = productInfo;
  const uniqueByImageURL = uniqBy(productInfoDetails?.garments ?? [], 'image_url');
  const currentProductImage = uniqueByImageURL[previewImageIndex]?.image_url;

  const itemInGallery = tryOnTaskResults.find((el: any) => el.product.datz === productInfoDetails._id);
  const isAlreadyInGallery = !!itemInGallery;
  const isProcessing = ['PROGRESS', "UNKNOWN"].includes(itemInGallery?.state)

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', px: '80px', pb: '120px', pt: '80px', position: 'relative'}}>
      <Box sx={{display: 'flex', gap: '30px'}}>
        {/*info*/}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column', flex: 1,
          direction: 'rtl',
        }}>
          <Box sx={{fontWeight: 900, fontSize: '2.2rem'}}>
            {name}
          </Box>

          <Box sx={{width: '300px', my: '30px', height: '2px', backgroundColor: '#fff'}}/>

          <Box sx={{position: 'relative'}}>
            <Box sx={{fontSize: '2rem', fontWeight: 900, marginBottom: '20px'}}>סוג פריט: {item_type}</Box>

            <Box sx={{fontSize: '2rem', fontWeight: 900, marginBottom: '20px'}}> צבע: {color}</Box>
            <Box sx={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row',
              flexShrink: 0,
              marginBottom: '20px'
            }}>
              {color_hex.map((color: string) => <Box key={color} sx={{
                  flex: 1,
                  flexShrink: 0,
                  backgroundColor: color
                }}
                />
              )}
            </Box>
          </Box>

          {/*info and actions*/}
          <Box sx={{mt: '152px'}}>
            {/*actions*/}
            <Box sx={{display: 'flex', mb: '40px', position: 'relative'}}>

              {!hidePrimaryAction && (
                <>

                  {isAlreadyInGallery && <OutlinedButton onClick={goToGallery} disabled={isProcessing}
																												 sx={{
                                                           marginLeft: '20px !important',
                                                           flex: 1,
                                                           minWidth: '340px'
                                                         }}>
                    {isProcessing ? `${itemInGallery.progress}%` : `לראות בארון בגדים`}
									</OutlinedButton>
                  }

                  {!isAlreadyInGallery && <OutlinedButton onClick={handleTryOn}
																													sx={{
                                                            marginLeft: '20px !important',
                                                            flex: 1,
                                                            minWidth: '340px'
                                                          }}>
                    {`למדידה וירטואלית`}
									</OutlinedButton>
                  }
                </>
              )}

              <OutlinedButton
                sx={{
                  '&.MuiButton-outlined': {
                    padding: '20px !important',
                  },
                  svg: {
                    color: theme => `${isAddedToWishlist ? theme.palette.secondary.main : '#fff'} !important`,
                  }
                }}
                onClick={handleToggleInWishlist}
              >
                {isAddedToWishlist ? <Favorite sx={{width: '52px', height: '52px'}}/> :
                  <FavoriteBorder sx={{width: '52px', height: '52px'}}/>}
              </OutlinedButton>
            </Box>

            <Box sx={{fontSize: '2.2rem', fontWeight: 900, mb: '20px'}}>
              תיאור
            </Box>

            <Box sx={{fontSize: '2rem', fontWeight: 900}}>
              {description}
            </Box>
          </Box>
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column'}}>

          {/*images*/}
          <Box sx={{display: 'flex', gap: '20px', maxHeight: '390px', overflow: 'hidden',}}>
            {/*main*/}
            <Box sx={{width: '260px'}}>
              <img src={currentProductImage} alt={name}/>
            </Box>
            {/*little*/}
            <Box
              sx={{display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'scroll', scrollbarWidth: 'none'}}>
              {uniqueByImageURL.map(({image_url}: any, index: number) => (
                <Box
                  key={image_url}
                  sx={{
                    width: '64px',
                    borderRadius: '6px',
                    border: theme => `3px solid ${previewImageIndex === index ? theme.palette.secondary.main : 'transparent'} !important`,
                  }}
                  onClick={() => setPreviewImageIndex(index)}
                >
                  <img src={image_url} alt={name} style={{
                    borderRadius: '4px',
                  }}/>
                </Box>
              ))}
            </Box>

          </Box>

          <Box sx={{
            marginTop: '32px',
            img: {
              width: '260px',
            }
          }}>
            {/*<Box sx={{position: 'absolute', width: '250px', top: '540px', right: '80px'}}>*/}
            <img src={product_url_qr} alt="qr url"/>


          </Box>
        </Box>

      </Box>
      {!!hasCoupon &&
        (
          <>
            <Box sx={{
              width: '100%',
              height: '2px',
              backgroundColor: '#fff',
              mx: 'auto',
              my: '40px'
            }}/>
            <Box sx={{direction: 'rtl', textAlign: 'center', fontSize: '2.2rem',}}>
              <Box>למשלוח חינם הזינו את קוד הקופון: <br/><span
                style={{fontWeight: 'bold', textDecoration: 'underline'}}>{couponName}</span></Box>

            </Box>
          </>
        )
      }

    </Box>
  )
}


export const ProductCard: React.FC<{
  product: any,
  // isAlreadyInGallery: boolean,
  onClickSelect?: any,
  onInfo: any,
  handleToggleInWishlist: any,
  isAddedToWishlist: boolean,
  buttonStates: any,
  isSelectable?: boolean
}> = ({
        // isAlreadyInGallery,
        product,
        onClickSelect,
        buttonStates,
        onInfo,
        isAddedToWishlist,
        handleToggleInWishlist,
        isSelectable,
      }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const theme = useTheme();
  const availableColors = product?.garments[0]?.color_hex ?? [];
  const colorName = product?.garments[0]?.color ?? '';

  return (
    <Box
      sx={{
        position: "relative",
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      }}
    >
      {product != undefined &&
				<Box
					onClick={() => {
            isSelectable ? onClickSelect({option: 0, prod: product}) : onInfo(product);
          }}
					sx={{
            backgroundColor: buttonStates[product._id] && imageLoaded ? theme.palette.secondary.main : '#fff',
            boxShadow: buttonStates[product._id] && imageLoaded ? 'rgba(0, 0, 0, 0.3) -12px 12px 12px 0px' : 'none',
            transition: '0.2s',
            position: 'relative',
            borderRadius: '4px',

            '&:before': {
              transition: '0.2s',
              zIndex: -9999,
              content: '""',
              display: 'block',
              width: '100%',
              height: '100%',
              background: buttonStates[product._id] && imageLoaded ? '#fff' : 'transparent',
              position: 'absolute',
              transform: buttonStates[product._id] && imageLoaded ? 'translate(-12px, 12px)' : 'none',
              borderRadius: '4px',
            }
          }}>
					<img
						onLoad={() => setImageLoaded(true)}
						style={{
              objectFit: 'cover',
              borderRadius: '4px',
              height: '337px',
              transition: '0.2s',
              transform: buttonStates[product._id] && imageLoaded ? 'translate(12px, -12px)' : 'none',
              boxShadow: buttonStates[product._id] && imageLoaded ? '-12px 12px 12px 0px rgba(0, 0, 0, 0.3)' : 'none',
            }}
						src={product.garments[0].image_url}
						alt={product.garments[0].name}
					/>
				</Box>
      }


      {product != undefined &&
				<Box style={{display: 'flex', flexDirection: 'column', margin: 0, padding: 0}}>
					<Box sx={{
            display: "inline",
            marginTop: '8px',
            padding: 0,
            fontSize: '1.8rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'end',
            direction: 'rtl'
          }} onClick={() => onInfo(product)}>
            {product.garments[0].name}
					</Box>
					<Box onClick={() => onInfo(product)}
							 sx={{display: "flex", flexDirection: "row", alignItems: 'center', height: '40px'}}>
						<Box
							sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid rgba(255,255,255,0.3) !important`,
                width: '26px',
                height: '26px',
                marginLeft: '8px',
                borderRadius: '6px',
              }}
						>

							<Box sx={{
                width: '22px',
                height: '22px',
                borderRadius: '4px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                flexShrink: 0,
              }}>
                {availableColors.map((color: string) => <Box key={color} sx={{
                    flex: 1, flexShrink: 0,
                    backgroundColor: color
                  }}/>
                )}
							</Box>
						</Box>

						<Box sx={{marginLeft: '12px', fontSize: '1.6rem'}}>{colorName}</Box>
					</Box>
				</Box>
      }
      {!!product && <Box sx={{
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        zIndex: 1000
      }}>
        {isSelectable && <LargeCheckbox
					size="medium"
					checked={!!buttonStates[product._id]}
					onChange={(e) => {
            e.stopPropagation();
            isSelectable && onClickSelect({option: 0, prod: product});
          }}
				/>}
        {/*{isSelectable && <LargeCheckbox*/}
        {/*	size="medium"*/}
        {/*	checked={!!buttonStates[product._id]}*/}
        {/*	onChange={(e) => {*/}
        {/*    e.stopPropagation();*/}
        {/*    onClickSelect({option: 0, prod: product});*/}
        {/*  }}*/}
        {/*/>}*/}

				<IconButton
					onClick={() => onInfo(product)}
					sx={{
            padding: '9px !important',
            color: '#000 !important',
            svg: {
              width: '50px',
              height: '50px',
            }
          }}>

					<InfoOutlined/>
				</IconButton>

				<IconButton
					onClick={() => handleToggleInWishlist(product)}
					sx={{
            padding: '9px !important',
            color: `${isAddedToWishlist ? theme.palette.secondary.main : '#000'} !important`,
            svg: {
              width: '50px',
              height: '50px',
            }
          }}>

          {isAddedToWishlist ? <Favorite/> : <FavoriteBorder/>}
				</IconButton>
			</Box>
      }
    </Box>
  );
}


const productCardWidth = 225;
const columnGap = 40;

export default function VirtualGridProducts({
                                              gender,
                                              handleTryOn,
                                              goToGallery,
                                              tryOnTaskResults,
                                              selectionLimit,
                                              handleToggleInWishlist,
                                              wishList,
                                              itemType,
                                              color,
                                              currentPage,
                                              setCurrentPage
                                            }: any) {
  const [products, setProducts] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(9);

  const [loading, setLoading] = useState(false);
  const [productsSelected, setButtonStates]: any = useState({})
  const [productInfoDetails, setProductInfoDetails] = useState<any>(null);

  const fetchGarments = async (page: number = 1, gender: string, itemType: string, color: string) => {
    setLoading(true);
    const garmentsData = await callApiGetGarments(page, pageSize, gender, itemType, color);
    // todo remove after demo
    const tempReplacedNameWithDescription = garmentsData.items.map((item: any) => ({
      ...item,
      garments: item.garments.map((el: any) => ({...el, name: el.description}))
    }));

    setProducts(tempReplacedNameWithDescription);
    setTotalPages(garmentsData.pagination.total_pages);
    setLoading(false);
  };

  // to prevent multiple requests
  const debouncedFetchGarments = useCallback(debounce(fetchGarments, 100), []);


  useEffect(() => {
    debouncedFetchGarments(currentPage, gender, itemType, color);
  }, [currentPage, gender, itemType, color]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  function doClick(data: any) {
    const updatedState = {
      ...productsSelected,
    };

    if (productsSelected[data.prod._id]) {
      delete updatedState[data.prod._id];
    } else {
      updatedState[data.prod._id] = data.prod;
    }
    setButtonStates(updatedState);
  }

  function handleGridTryOn() {
    handleTryOn(productsSelected);
    setButtonStates({});
    setLoading(true);
  }

  const handleInfo = (product: any) => {
    setProductInfoDetails(product);
  }

  const selectedLength = Object.values(productsSelected).filter(Boolean).length;

  const checkIsInWishlist = (product: any) => {
    return !!wishList.find((el: any) => el._id === product._id)
  }

  const [openFilters, setOpenFilters] = useState(false);

  return (
    <Box sx={{
      position: "absolute",
      width: `${productCardWidth * 3 + 2 * columnGap}px`,
      left: '100px',
      top: '200px',
      display: "flex",
      flexDirection: "column",
    }}>


      {/*<OutlinedButton*/}
      {/*  onClick={() => setOpenFilters(true)}*/}
      {/*  sx={{*/}
      {/*    width: '168px',*/}
      {/*    height: '168px',*/}
      {/*    position: 'fixed',*/}
      {/*    top: '198px',*/}
      {/*    right: '0',*/}
      {/*    zIndex: 999,*/}
      {/*    svg: {*/}
      {/*      fill: '#fff',*/}
      {/*      width: '64px',*/}
      {/*      height: '64px',*/}
      {/*    },*/}
      {/*  }}>*/}
      {/*  <Tune/>*/}
      {/*</OutlinedButton>*/}

      <Dialog
        PaperProps={{
          sx: {
            color: '#fff',
            backgroundColor: 'rgba(19, 19, 19, 0.95)'
          },
        }}
        maxWidth="lg" open={openFilters} onClose={() => setOpenFilters(false)}
      >

        <Box sx={{
          padding: '60px',
          svg: {
            // fill: '#fff',
            // width: '64px',
            // height: '64px',
          },
        }}>
          <OutlinedButton color="secondary">
            <Male
              sx={{
                width: '64px', height: '64px'

              }}/>
          </OutlinedButton>

          <OutlinedButton>
            <Female
              sx={{
                width: '64px', height: '64px'

              }}/>
          </OutlinedButton>
        </Box>
      </Dialog>


      <Dialog PaperProps={{
        sx: {
          maxHeight: 'unset !important',
          color: '#fff',
          backgroundColor: 'rgba(19, 19, 19, 0.95)'
        },
      }} maxWidth="lg" open={!!productInfoDetails} onClose={() => setProductInfoDetails(null)}>
        {!!productInfoDetails && <ProductInfo
					productInfoDetails={productInfoDetails}
					isAddedToWishlist={checkIsInWishlist(productInfoDetails)}
					handleToggleInWishlist={() => handleToggleInWishlist(productInfoDetails)}

					goToGallery={goToGallery}
					tryOnTaskResults={tryOnTaskResults}
					handleTryOn={() => handleTryOn({[productInfoDetails._id]: productInfoDetails}, true)}
				/>}
      </Dialog>

      <Box sx={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00FF0000"
      }}>

        <Box sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: `${columnGap}px`,
          rowGap: '40px'
        }}>
          {products?.map((product: any, index: number) => (
              <Fade in key={product._id}
                    style={{transitionDelay: `${index * 40}ms`}}
              >

                <Box key={product._id} sx={{width: `${productCardWidth}px`}}>
                  <ProductCard
                    isSelectable
                    handleToggleInWishlist={handleToggleInWishlist}
                    onInfo={handleInfo}
                    product={product}
                    onClickSelect={doClick}
                    buttonStates={productsSelected}
                    isAddedToWishlist={checkIsInWishlist(product)}
                  />
                </Box>
              </Fade>

            )
          )}

          {!!products.length && (
            <>
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    fontSize: '2.2rem',
                    '.MuiPaginationItem-ellipsis': {color: '#fff', fontSize: '2.2rem'},
                    '.Mui-selected': {
                      color: ({palette}) => `${palette.secondary.main} !important`,
                      fontWeight: 'bolder !important'
                    },
                    li: {mx: 1},
                    svg: {width: '46px', height: '46px',}
                  }}
                />
              </Box>
              <OutlinedButton
                disabled={!selectedLength || selectedLength > selectionLimit}
                onClick={handleGridTryOn}
                sx={{width: '100%'}}
              >
                {loading && <CircularProgress sx={{color: '#fff', position: 'absolute'}}/>}
                <Box sx={{visibility: loading ? 'hidden' : 'visible'}}>
                  {`למדידה וירטואלית`} {selectedLength}/{selectionLimit}
                </Box>

              </OutlinedButton>
            </>
          )}

        </Box>

        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
          <CircularProgress color="inherit"/>
        </Backdrop>

      </Box>
    </Box>
  )
};
