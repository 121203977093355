"use client";

import { useAuth } from '../context/AuthContext';
import { useRouter } from 'next/navigation';
import { useEffect, ReactNode, useState } from 'react';
import { usePathname } from 'next/navigation';
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    const { isLoggedIn } = useAuth();
    const router = useRouter();
    const pathname = usePathname();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Bypass the auth check if the current route is '/login'
        if (pathname === '/login') {
            setIsLoading(false);
            return;
        }
        if ((pathname !== '/login') && (isLoggedIn == false)) {
            setIsLoading(true);
        }
        if (!isLoggedIn) {
            console.log('Not authenticated, redirecting to login');
            router.push('/login');
        } else {
            setIsLoading(false);
        }
    }, [isLoggedIn, pathname, router]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return <>{children}</>;
};

export default ProtectedRoute;