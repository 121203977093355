import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import { jwtDecode } from "jwt-decode";

interface AuthContextType {
  hasCoupon: boolean;
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
const demoUsernameWithCoupon = 'demo';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
const [hasCoupon, setHasCoupon] = useState(false);
  const router = useRouter();

  useEffect(() => {
    // Check local storage or cookie for login state
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
    if (loggedIn) {
      const token = localStorage.getItem('token');
      // @ts-ignore
      if(jwtDecode(token)?.sub === demoUsernameWithCoupon) {
        setHasCoupon(true)
      }
      router.push('/');
    }
  }, []);

  const login = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    //TODO: fix this, now force reload in order for getting correct state from local storage
    window.location.reload();
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.setItem('role', '');
    //TODO: fix this, now force reload in order for getting correct state from local storage
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, hasCoupon }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
