export const rotateBase64Image = (base64Image: string, angle = -90): Promise<string> => {
  navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
    const cameras = mediaDevices.filter(({ kind }) => kind === "videoinput");
    console.log('DEBUG cameras', cameras.length, cameras)
  });

  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    img.onload = () => {
      // Calculate dimensions based on rotation
      const rad = angle * Math.PI / 180
      const absAngle = Math.abs(angle % 360)
      const isVerticalRotation = absAngle === 90 || absAngle === 270

      canvas.width = isVerticalRotation ? img.height : img.width
      canvas.height = isVerticalRotation ? img.width : img.height

      // Perform rotation
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(rad)
      ctx.drawImage(
        img,
        -img.width / 2,
        -img.height / 2
      )

      const rotatedBase64 = canvas.toDataURL('image/jpeg', 1)
      resolve(rotatedBase64)
    }

    img.onerror = reject
    img.src = base64Image
  })
}
