import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";

const OutlinedButton = styled(Button, {shouldForwardProp: (prop) => true})(
  ({theme}) => ({
    '&.MuiButton-outlined': {
      fontSize: '2.2rem !important',
      border: '2px solid !important',
      padding: '14px 36px !important',
      fontWeight: 'bold !important',
      color: '#fff !important',
      borderRadius: 0,
      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.5) !important',
        borderColor: 'rgba(255, 255, 255, 0.5) !important',
      }
    },
  }),
);

OutlinedButton.defaultProps = {
  variant: 'outlined',
  color: 'primary',
  size: 'large'
}

export default OutlinedButton;