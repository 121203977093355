import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";

const TextButton = styled(Button, {shouldForwardProp: (prop) => true})(
  ({theme}) => ({
    '&.MuiButton-text': {
      fontSize: '2.2rem !important',
      padding: '14px 36px !important',
      color: 'rgba(255, 255, 255, 0.7) !important',
    },
  }),
);

TextButton.defaultProps = {
  variant: 'text',
  color: 'primary',
  size: 'large'
}

export default TextButton;