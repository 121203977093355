"use client";
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Close from '@mui/icons-material/Close';
import {logout} from "@/app/common/api";

const getCompanyLogoURL = () => {
  const companyData = localStorage.getItem('company')

  if(!companyData) return null;

  let parsedData = null;

  try {
    parsedData = JSON.parse(companyData)
  } catch {
    return null
  }

  return parsedData?.logo_image_url ?? null
}

export default function StoreLayout({children, onLogoClick, showTermsAndConditions = false, onHiddenBoxClick}: any) {
  const [termsAndConditionDialog, setTermsAndConditionDialog] = useState(false);

  const openTermsAndConditionDialog = () => {
    setTermsAndConditionDialog(true)
  }

  const closeTermsAndConditionDialog = () => {
    setTermsAndConditionDialog(false)
  }



  const companyLogoURL = getCompanyLogoURL()


  return (
    <Box sx={{
      backgroundImage: 'url(https://storage.gonilabs.com/assets/store_background.png) !important',
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      backgroundColor: 'transparent',
      position: 'absolute',
      height: '100vh',
      width: '100vw',
      zIndex: '800',
    }}
    >

      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 9999,
          // padding: '40px 0',
          // maxHeight: '150px',
          left: '50%',
          top: '40px',
          transform: 'translate(-50%, 0)',
          img: {
            height: '100%',
            maxHeight: '130px !important',
            width: 'auto !important',
            objectFit: 'contain'
          }
        }}
      >

        {!!companyLogoURL && <img
          onClick={onLogoClick}
          src={companyLogoURL}
          alt="Company logo"
        />}
      </Box>

      {children}

      {showTermsAndConditions && <Box sx={{
        position: 'absolute',
        fontSize: '2rem',
        width: '100%',
        textAlign: 'center',
        mt: 4,
        bottom: '20px',
        zIndex: 9999
      }}>
				<Button variant="text" onClick={openTermsAndConditionDialog}>לתנאי השימוש ומדיניות פרטיות</Button>
			</Box>}


      <Dialog
        maxWidth="md"
        open={termsAndConditionDialog}
        onClose={closeTermsAndConditionDialog}

        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'rgba(19, 19, 19, 0.9)', color: '#fff',  padding: '110px 40px 60px 40px'        }
        }}
      >

        <IconButton onClick={closeTermsAndConditionDialog} sx={{
          padding: '10px!important',
          position: 'absolute',
          top: '20px',
          right: '40px',
          zIndex: 9,
          svg: {
            fill: '#fff',
            width: '64px',
            height: '64px',
          },
        }}>
          <Close />
        </IconButton>

          <DialogTitle sx={{
            fontSize: '3rem !important', direction: 'rtl',
          }}>תנאי שימוש ומדיניות פרטיות</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
                fontSize: '2rem',
                direction: 'rtl',
              }}
            >
              מראת גוני משתמשת במצלמה, שכן המצלמה מעניקה לנו את היכולת לתת לכם את השירות .
              המידע האישי שלך שאספנו עליך בשימוש במראה נמחק לאחר השימוש שלך. אנחנו לא שומרים תמונת פנים או שום מידע אישי כגון שם מלא או פרטי תשלום כתובת וכדומה .
              ארגון וניתוח של מידע עבור הקהילה המתרחבת שלנו הם מרכיב מרכזי בשירות שלנו. חלק גדול מהשירות הוא יצירה ושימוש בטכנולוגיות מתקדמות שעוזרות לנו להתאים אישית את השירות שלנו, להגן עליו ולשפר אותו בהיקפים גדולים במיוחד למען קהילה עולמית רחבה. טכנולוגיות כמו בינה מלאכותית ולמידת מכונה מעניקות לנו את הכוח ליישם תהליכים מורכבים בשירות שלנו על כל חלקיו. טכנולוגיות אוטומטיות עוזרות לנו גם להבטיח את הפונקציונליות והתקינות של השירות שלנו.
              שים לב שהמידע האישי שלך לא מופץ או משותף עם שום גורם או מכונה שתאפשר זיהוי שלך בתור משתמש בשום שלב .
              המידע שאנו אוספים ומעבדים משמש אותנו לשיפור יכולות ההלבשה של החברה, כאשר המידע מעובד ללא תמונת פנים וללא יכולת זיהוי .לדוגמה, אם מדדת בגד מול המראה אנחנו מעבדים את התמונה של לפני ואחרי המדידה על מנת לשפר את האלגוריתם שלנו  ולא יודעים למי שייכת התמונה או יכולים לשייך אותה אליך בשום שלב.

            </Box>
          </DialogContent>
      </Dialog>

      {!!onHiddenBoxClick && <Box onClick={onHiddenBoxClick} sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '200px',
        height: '200px',
        backgroundColor: 'transparent',
        zIndex: 99999
      }}/>}
    </Box>
  )
};
