import {styled} from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

const LargeCheckbox = styled(Checkbox, {shouldForwardProp: (prop) => true})(
  ({theme}) => ({
    '&:before': {
      content: "''",
      background: '#fff',
      width: '50%',
      height: '50%',
      position: 'absolute',
      borderRadius: '4px',
      zIndex: '-1',
},
    color: "#000 !important",

    '&.MuiCheckbox-sizeLarge': {
      svg: {
        width: '60px',
        height: '60px'
      }
    },
    '&.MuiCheckbox-sizeMedium': {
      svg: {
        width: '50px',
        height: '50px'
      }
    }
  }),
);

LargeCheckbox.defaultProps = {
  size: 'large'
}

export default LargeCheckbox;