"use client";
import React, {useEffect, useState} from 'react';
import ToggleButtonList from './ToggleButtonList';

import {
  callApiGetColors,
  callApiGetVirtualTryOnTaskStatus,
  callApiSubmitVirtualTryOnTask,
  callApiGetItemTypes
} from '../common/api';
import VirtualGridProducts, {ProductCard, ProductInfo} from './VirtualGridProducts'
import {
  Box,
  Fade,
  Slide,
  Zoom,
  IconButton
} from '@mui/material';
import SubjectFrames from './SubjectFrames';
import {TryOnGallery} from "@/app/components/TryOnGallery";

// @ts-ignore
import xorBy from 'lodash/xorBy';
import Dialog from "@mui/material/Dialog";

enum TryOnSteps {
  StepSelectImage,
  StepFilterProducts,
  StepShowResout,
  StepProcessTryOn,
  StepWishlist
}

const ALLOWED_NUMBER_OF_PRODUCTS_TO_TRY_ON = 5;

const useTryOn = () => {
  // todo remove before pushing
  // const [tryOnTaskResults, setTryOnTaskResults] = useState<any[]>(shortcutTryOnResults);
  const [tryOnTaskResults, setTryOnTaskResults] = useState<any[]>([]);
  const tryOnProducts = async ({subjectImage, selectedProducts}: { subjectImage: string, selectedProducts: any[] }) => {
    const base64Response = await fetch(subjectImage);
    const blob = await base64Response.blob();
    const file = new File([blob], 'subject_image', {type: 'image/jpeg'});

    const tasks = selectedProducts.map((selectedProduct) => {
      const product = selectedProduct.garments[0] ?? {};
      const productImgUrl = product.image_url as string
      const productCategory = product.category as string
      const shirtType = product.shirt_type as string;
      return {
        product,
        parentProduct: selectedProduct,
        promise: callApiSubmitVirtualTryOnTask(file, productImgUrl, productCategory, shirtType)
      };
    })

    tasks.forEach(task => {
      task.promise?.then((taskResponse) => {
        if (taskResponse.status === 'success') {
          const intervalId = setInterval(async () => {
            const {
              status,
              message,
              data: {
                state,
                task_id,
                virtual_try_on_photo_b64,
                info = {}
              }
            } = await callApiGetVirtualTryOnTaskStatus(
              taskResponse.data.task_id)

            if (status === 'error') {
              console.error('Error while trying on product', task_id, message);
              // just to cleanup the failed task from the list
              setTryOnTaskResults(prev => prev.filter((taskResult) => taskResult?.task_id !== taskResponse?.data?.task_id))
              return clearInterval(intervalId);
            }

            if (state === 'PROGRESS') {
              setTryOnTaskResults(prev => prev.map((taskResult) => {
                  return taskResult.task_id === task_id ? {
                    ...taskResult,
                    state,
                    progress: info.progress ?? 0
                  } : taskResult
                })
              )
            }
            if (state === 'COMPLETED' || info.progress === 100) {
              setTryOnTaskResults(prev => prev.map((taskResult, index) => {
                return taskResult.task_id === task_id ? {
                  ...taskResult,
                  state,
                  progress: 100,
                  base64TryOnResult: `data:image/jpeg;base64,${virtual_try_on_photo_b64}`
                } : taskResult
              }))
              clearInterval(intervalId);
            }
          }, 1000);

          const taskData = {
            task_id: taskResponse.data.task_id,
            product: task.product,
            parentProduct: task.parentProduct,
            progress: 0,
            intervalId,
            base64TryOnResult: '',
            state: 'UNKNOWN'
          };

          setTryOnTaskResults((prev) => [...prev, taskData]);
        }
      })
    })
  }

  useEffect(() => {
    return () => {
      tryOnTaskResults.forEach(({intervalId}) => {
        clearInterval(intervalId);
      })
    }
  }, [])

  return {tryOnProducts, tryOnTaskResults}
}

export default function VirtualCamera({subjectImages,
                                        goHome,
                                        handleTakePictureFinish,
                                        resetShortcutFlow,
                                        handleRetakePicture,
                                        isShortcutFlow
                                      }: any) {
  const [tryOnStep, setTryOnStep] = useState<TryOnSteps>(TryOnSteps.StepSelectImage);
  const {tryOnProducts, tryOnTaskResults} = useTryOn();
  const [showTryOnGallery, setShowTryOnGallery] = useState(false)

  const [gender, setGender] = useState('M');
  const [genders] = useState([{value: 'W', label: 'נשים'}, {value: 'M', label: 'גברים'}]);

  const [color, setColor] = useState('');
  const [colors, setColors] = useState<any[]>([]);
  const [colorsLoading, setColorsLoading] = useState(false);

  const [itemType, setItemType] = useState('');
  const [itemTypes, setItemTypes] = useState<any[]>([]);
  const [itemTypesLoading, setItemTypesLoading] = useState(false);

  const [selectedSubjectImageIndex, setSelectedSubjectImageIndex] = useState(-1);
  const subjectImage = subjectImages[selectedSubjectImageIndex];

  useEffect(() => {
    fetchColors(gender)
    fetchItemTypes(gender)
    // when gender changes we need to make sure to drop other filters to avoid unexisting combo/results
    setItemType('');
    setColor('');
  }, [gender])

  const fetchColors = async (gender?: string) => {
    setColorsLoading(true);
    const colorsData = await callApiGetColors(gender);
    setColors(colorsData);
    setColorsLoading(false);
  }

  const fetchItemTypes = async (gender?: string) => {
    setItemTypesLoading(true);
    const itemTypesData = await callApiGetItemTypes(gender);
    setItemTypes(itemTypesData);
    setItemTypesLoading(false);
  }


  function handleContinue() {
    // setSubjectImage(subjectImage);
    // resetShortcutFlow();
    setTryOnStep(TryOnSteps.StepFilterProducts);
  }

  function handelSubjectRetake() {
    // resetShortcutFlow()
    setSelectedSubjectImageIndex(-1);
    handleRetakePicture();
  }

  const handleTryOn = async (selectedProducts: any, skipLimit = false) => {
    if (!subjectImage) return;

    const selectedProductsList = Object.values(selectedProducts)

    if (!selectedProductsList.length) return;
    if (selectedProductsList.length > ALLOWED_NUMBER_OF_PRODUCTS_TO_TRY_ON && !skipLimit) return;

    await tryOnProducts({subjectImage, selectedProducts: selectedProductsList});
    setShowTryOnGallery(true)
  }

  const [wishList, setWishList] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // we want to reset the page when filter changes
    setCurrentPage(1);
  }, [itemType, color, gender]);

  const handleToggleInWishlist = (product: any) => {
    setWishList(prev => xorBy(prev, [product], '_id'))
  }

  const handleGoToWishlist = () => {
    setTryOnStep(TryOnSteps.StepWishlist);
  }

  if (showTryOnGallery) {
    return (
      <Zoom in>
        <Box>
          <TryOnGallery
            closeGallery={() => setShowTryOnGallery(false)}
            subjectFrame={subjectImage}
            tryOnList={tryOnTaskResults}
            wishList={wishList}
            handleToggleInWishlist={handleToggleInWishlist}
          />
        </Box>
      </Zoom>
    )
  }

  // @ts-ignore
  return (
    <div
      style={{
      display: "flex", position: 'relative',
      height: '100vh', width: '100vw', zIndex: '800',
    }}
    >
      {tryOnStep == TryOnSteps.StepSelectImage &&
				<SubjectFrames
					subjectImages={subjectImages}
					selectedSubjectImageIndex={selectedSubjectImageIndex}
					setSelectedSubjectImageIndex={setSelectedSubjectImageIndex}
					handleRetake={handelSubjectRetake}
					handleContinue={handleContinue}
				/>
      }

      {[TryOnSteps.StepFilterProducts, TryOnSteps.StepWishlist].includes(tryOnStep) &&
				<div style={{
          position: "absolute", display: "flex", alignItems: "center",
          top: 0,
          right: 0,
          height: window.screen.height,
          backgroundColor: "#FF000000"
        }}
				>
					<ToggleButtonList
						onPhotoRetake={goHome}
            // @ts-ignore
						isWishListOpen={tryOnStep == TryOnSteps.StepWishlist}
						isProductsListOpen={tryOnStep == TryOnSteps.StepFilterProducts}

						onProductsListClick={() => setTryOnStep(TryOnSteps.StepFilterProducts)}

						tryOnTaskResults={tryOnTaskResults}
						onTryOnGalleryClick={() => setShowTryOnGallery(true)}

						colors={colors}
						color={color}
						setColor={setColor}

						itemTypes={itemTypes}
						itemType={itemType}
						setItemType={setItemType}

						genders={genders}
						gender={gender}
						setGender={setGender}

						wishList={wishList}
						onWishListClick={handleGoToWishlist}
					/>
				</div>
      }

      {tryOnStep == TryOnSteps.StepFilterProducts &&
				<VirtualGridProducts
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					selectionLimit={ALLOWED_NUMBER_OF_PRODUCTS_TO_TRY_ON}
					wishList={wishList}
					handleToggleInWishlist={handleToggleInWishlist}
					handleTryOn={handleTryOn}
					goToGallery={() => setShowTryOnGallery(true)}

					gender={gender}
					itemType={itemType}
					color={color}
					selectedPhoto={subjectImages[selectedSubjectImageIndex]}
					tryOnTaskResults={tryOnTaskResults}
				/>
      }

      {tryOnStep == TryOnSteps.StepWishlist && (
        <WishList
          wishList={wishList}
          goToGallery={() => setShowTryOnGallery(true)}
          tryOnTaskResults={tryOnTaskResults}
          handleUpdateWishlist={setWishList}
        />
      )}

    </div>
  );
}

const WishList = ({wishList, handleTryOn, handleUpdateWishlist, tryOnTaskResults, goToGallery}: any) => {
  const productCardWidth = 225;
  const [tempWishList, setTempWishList] = useState([...wishList])
  const [productInfoDetails, setProductInfoDetails] = useState<any>(null);

  const checkIsInTempWishList = (product: any) => {
    return tempWishList.some((item) => item._id === product._id)
  }

  const handleToggleInTempWishlist = (product: any) => {
    setTempWishList((prev) => xorBy(prev, [product], '_id'))
  }

  const handleInfo = (product: any) => {
    setProductInfoDetails(product);
  }

  useEffect(() => {
    return () => {
      // update on unmount so that it does not jump when user interacts with the grid
      setTempWishList(prev => {
        handleUpdateWishlist(prev)
        return prev
      })
    }
  }, [])

  return (
    <Box
      sx={{
        display: "flex", position: 'relative',
        height: '100vh', width: '100vw',
      }}
    >
      <Box sx={{fontSize: '3rem', textAlign: 'center', width: '100%', top: '200px', position: 'absolute'}}>
        {!wishList.length && 'עדיין לא נוספו מוצרים לרשימת המשאלות'}
      </Box>


      <Box sx={{
        height: '80vh',
        overflow: 'scroll',
        scrollbarWidth: 'none',
        position: 'absolute',
        width: '755px',
        left: '100px',
        top: '200px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '40px',
        }}>

          {wishList.map((product: any, index: number) => (
            <Fade
              in
              key={product._id}
              style={{transitionDelay: `${index * 40}ms`}}
            >

              <Box key={product._id} sx={{width: `${productCardWidth}px`}}>
                <ProductCard
                  product={product}
                  isAddedToWishlist={checkIsInTempWishList(product)}
                  isSelectable={false}
                  buttonStates={{}}
                  handleToggleInWishlist={() => handleToggleInTempWishlist(product)}
                  onInfo={handleInfo}
                />
              </Box>
            </Fade>
          ))}

        </Box>

        <Dialog PaperProps={{
          sx: {
            color: '#fff',
            backgroundColor: 'rgba(19, 19, 19, 0.95)'
          },
        }} maxWidth="lg" open={!!productInfoDetails} onClose={() => setProductInfoDetails(null)}>
          {!!productInfoDetails && <ProductInfo
						productInfoDetails={productInfoDetails}
						isAddedToWishlist={checkIsInTempWishList(productInfoDetails)}
						handleToggleInWishlist={() => handleToggleInTempWishlist(productInfoDetails)}
						tryOnTaskResults={tryOnTaskResults}
						goToGallery={goToGallery}
						handleTryOn={() => handleTryOn({[productInfoDetails._id]: productInfoDetails}, true)}
					/>}
        </Dialog>

      </Box>
    </Box>
  )
}