"use client";
import * as React from 'react';
import { AuthProvider, useAuth } from './context/AuthContext'; // Ensure useAuth is imported from AuthContext
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ProtectedRoute from './components/ProtectedRoute';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import { ADMIN_ROLE, USER_ROLE } from './common/constants';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkroom from '@mui/icons-material/Checkroom';
import InventoryIcon from '@mui/icons-material/Inventory';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Link from 'next/link';
import Logout from './components/Logout';
import VirtualMainView from './components/VirtualMainView';


export default function RootLayout(props: { children: React.ReactNode }) {
  const [open, setOpen] = React.useState(true);
  const [role, setRole] = React.useState(USER_ROLE);

  React.useEffect(() => {
    // Get the stored value from localStorage if available
    let storedValue = localStorage.getItem('openDrawer');
    if (storedValue) {
      setOpen((storedValue === 'true'));
    }
    storedValue = localStorage.getItem('role');
    if (storedValue) {
      setRole(storedValue);
    }
  }, []);

  const toggleDrawer = () => {
    if (!open) {
      localStorage.setItem('openDrawer', 'true');
    } else {
      localStorage.setItem('openDrawer', 'false');
    }
    setOpen(!open);
  };
  return (
    <html lang="en">
      <body>
        <AppRouterCacheProvider options={{ enableCssLayer: true }}>
          <AuthProvider> {/* Ensure AuthProvider is here */}
            <ProtectedRoute>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <MainContent open={open} role={role} toggleDrawer={toggleDrawer}>
                  {props.children}
                </MainContent>
              </ThemeProvider>
            </ProtectedRoute>
          </AuthProvider>
        </AppRouterCacheProvider>
      </body>
    </html>
  );
}

function MainContent({ open, role, toggleDrawer, children }: any) {
  const { isLoggedIn } = useAuth(); // Get authentication state here

  const [selectedModelValue, setSelectedModelValue] = React.useState('m1');

  React.useEffect(() => {
    // Get the stored value from localStorage if available
    const storedValue = localStorage.getItem('selectedModelValue');
    if (storedValue) {
      setSelectedModelValue(storedValue);
    }
  }, []);

  const handleModelChange = (event: any) => {
    const value = event.target.value as string;
    setSelectedModelValue(value);
    localStorage.setItem('selectedModelValue', value);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      {isLoggedIn ? (
        <>
  
         <VirtualMainView/>
        </>
      ) : (
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
